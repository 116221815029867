import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"
import LayoutTwo from "../components/layoutTwo"
import Seo from "../components/seo"

const ThankYouPage = () => (
    <LayoutTwo>
        <div className="layout-padding-top"></div>
        <div className="error-page-wrapper d-flex align-items-center">
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col xl={8} className="text-center">
                        <h1>Thank You for Your Submission!</h1>
                        <p>We appreciate your time and effort in updating the required details. Your submission has been successfully received. If you have any questions or need further assistance, feel free to reach out to us.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <Link to="/contact" className="button button-primary button-with-icon"><i className="icon icon-banner-valuation"></i>Contact us</Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to="/" className="button button-primary-light button-with-icon"><i className="icon-valuation-home"></i>Home page</Link>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    </LayoutTwo>
)

export const Head = () => (
    <>
        <Seo title="Thank you" />
        <meta name="robots" content="noindex, nofollow" />
    </>
)

export default ThankYouPage
